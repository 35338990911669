import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const endpointEnv = 'https://ion.testnet.zestbloom.com';
//const ipfsGWEnv = 'https://ipfs.zbio.xyz'
const ipfsGWEnv = 'https://ipfs.io/ipfs'
//const ipfsGWEnv = 'https://gateway.pinata.cloud/ipfs';

/*
console.log({
  ionService: {
    endpointEnv,
  },
});
*/

export default {
  get: async (cid) => {
    return (
      (
        await axios.get(`${ipfsGWEnv}/${cid}`)
      )?.data || 0
    );
  },
  shuffleConfig: async (config) => {
    return (
      (
        await axios.post(`${endpointEnv}/ipfs/shuffles-config`, config)
      )?.data || 0
    );
  },
};
