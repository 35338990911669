import { loadStdlib } from "@reach-sh/stdlib";
import MyAlgoConnect from "@reach-sh/stdlib/ALGO_MyAlgoConnect";
import WalletConnect from "@reach-sh/stdlib/ALGO_WalletConnect";
//import { ALGO_MyAlgoConnect as MyAlgoConnect } from "@reach-sh/stdlib";
//import { ALGO_WalletConnect as WalletConnect } from "@reach-sh/stdlib";

let reach;

function getCurrentNetwork() {
  const { REACT_APP_NETWORK } = process.env;
  const networkEnv = REACT_APP_NETWORK || "ALGO";
  return networkEnv;
}

export function getCurrentProvider() {
  const { REACT_APP_NETWORK_PROVIDER } = process.env;
  const providerEnv = localStorage.getItem("providerEnv") || "MainNet";
  return providerEnv;
}

function getCurrentWallet() {
  return localStorage.getItem("walletFallback") || "MyAlgoConnect";
}

function reloadStdlib() {
  const networkEnv = getCurrentNetwork();
  const providerType = getCurrentProvider();
  let providerEnv;
  switch (providerType) {
    case "MainNet":
    case "MainNet-RandLabs":
    case "MainNet-AlgoNode":
      providerEnv = {
        ALGO_TOKEN: "",
        ALGO_SERVER: "https://mainnet-api.algonode.cloud",
        ALGO_PORT: "",
        ALGO_NODE_WRITE_ONLY: "no",

        ALGO_INDEXER_TOKEN: "",
        ALGO_INDEXER_SERVER: "https://mainnet-idx.algonode.cloud",
        ALGO_INDEXER_PORT: ""
      };
      break;
    case "TestNet":
    case "TestNet-RandLabs":
    case "TestNet-AlgoNode":
      providerEnv = {
        ALGO_TOKEN: "",
        ALGO_SERVER: "https://testnet-api.algonode.cloud",
        ALGO_PORT: "",
        ALGO_NODE_WRITE_ONLY: "no",

        ALGO_INDEXER_TOKEN: "",
        ALGO_INDEXER_SERVER: "https://testnet-idx.algonode.cloud",
        ALGO_INDEXER_PORT: ""
      };
      break;
  }
  console.log({ providerEnv });
  const wallet = getCurrentWallet();
  let stdlib = loadStdlib({
    REACH_CONNECTOR_MODE: getCurrentNetwork(),
  });
  //console.log(stdlib.providerEnvByName("TestNet"));
  if (networkEnv === "ALGO") {
    switch (wallet) {
      case "MyAlgoConnect":
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv,
            MyAlgoConnect,
          })
        );
        break;
      case "WalletConnect":
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv,
            WalletConnect,
          })
        );
        break;
      case "Mnemonic":
      default:
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv,
          })
        );
        break;
    }
  }
  return stdlib;
}

/** Global default reach object */
export function useReach() {
  if (!reach) {
    reach = reloadStdlib();
  }
  return reach;
}
